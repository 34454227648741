html,
body,
#root {
  margin: 0;
  height: 100%;
  width: 100%;
}
.ag-header-row-column-filter .ag-floating-filter-input .ag-react-container,
.ag-header-row-column-filter .ag-floating-filter-input .ag-react-container * {
  width: 100%;
}
.rmdp-container {
  .rmdp-input {
    background: transparent;
    text-align: right;
    color: var(--ag-foreground-color);
    font-family: inherit;
    font-size: inherit;
    height: calc(var(--ag-grid-size) * 5);
    padding-bottom: var(--ag-grid-size);
    border-width: 0;
    border-bottom: 2px solid;
    border-bottom-color: var(--ag-border-color);
  }

  .rmdp-input:focus {
    border: 0;
    outline: none !important;
    border-bottom: 2px solid;
    border-bottom-color: var(--ag-material-primary-color);
    outline: none;
    box-shadow: none;
  }
}
